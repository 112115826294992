<template>
<div class="wrapper">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
    <el-tab-pane label="基本信息" name="first">
      <add-or-edit></add-or-edit>
    </el-tab-pane>
    <el-tab-pane label="员工名单" name="second">
      <staff-list></staff-list>
    </el-tab-pane>
    <el-tab-pane label="核心服务" name="third">
      <core-serve></core-serve>
    </el-tab-pane>
    <el-tab-pane label="服务记录" name="fourth">
      <serviceRecord :serviceOrgIdProp = serviceOrgId v-if="isrecord"></serviceRecord>
    </el-tab-pane>
    <el-tab-pane label="服务对象" name="five">
      <serve-person></serve-person>
    </el-tab-pane>
    <el-tab-pane label="活动记录" name="six">
      <activity-record v-if="isActiveRecord"></activity-record>
    </el-tab-pane>
  </el-tabs>
</div>
</template>
<script>
import baseInfo from './components/baseInfo.vue'
import addOrEdit from './addOrEdit.vue'
import activityRecord from './components/activityRecord.vue'

import staffList from './components/staffList.vue'
import coreServe from './components/coreServe.vue'
import serviceRecord from '../../HomeCare/ServeRecords/list.vue'

import servePerson from './components/servePerson.vue'


export default {
    beforeRouteEnter (to, from, next) {
    next(vm => {
      if(from.name == 'addActivityRecord'){
        vm.activeName ='six'
        vm.isActiveRecord = true
      }
      // 通过 `vm` 访问组件实例
    })
},
  name: 'Detail',
  components: { baseInfo,staffList,coreServe, serviceRecord, servePerson,addOrEdit,activityRecord},
  data() {
    return {
      activeName: 'first',
      isrecord:false,
      serviceOrgId:(JSON.parse(this.$route.query.item)).id,
      isActiveRecord:false
    }
  },
  computed: {
  },
  created() {


  },
  methods: {
    handleClick(tab,event){
      if(tab.name=='fourth'){
        this.isrecord = true
      }else if(tab.name=='six'){
        this.isActiveRecord = true
      }else{
        this.isrecord = false
        this.isActiveRecord = false
      }}
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: auto;
  .m-content{
    .mc-item{
      line-height: 30px;
      span{
        display: inline-block;
        width: 150px;
        text-align: right;
      }
    }
  }
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
