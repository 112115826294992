<template>
  <div class="staff-list">
    <vxe-table
        border
        resizable
        auto-resize
        :key="Math.random()"
        show-header-overflow
        show-overflow
        highlight-hover-row
        :data="staffNameList">
      <vxe-table-column type="seq" title="序号" width="60" align="center"></vxe-table-column>
      <vxe-table-column field="name" title="姓名" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="sex" title="性别" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="age" title="年龄" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="contTel" title="联系方式" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="jobTitle" title="职称" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="workYears" title="从业年限" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="serviceSiteName" title="服务站点" minWidth="100" align="center"></vxe-table-column>


      <!-- <vxe-table-column field="jobTitle" title="职位" minWidth="100" align="center"></vxe-table-column> -->
      <!-- <vxe-table-column field="contTel" title="联系方式" minWidth="100" align="center"></vxe-table-column> -->
      <!-- <vxe-table-column field="address" title="个人证书" minWidth="200" show-overflow align="center"></vxe-table-column> -->
    </vxe-table>
    <!-- 分页 -->
    <div class="page">
      <vxe-pager :current-page.sync="tablePage.currentPage" :page-size.sync="tablePage.pageSize"
                 :page-sizes="tablePage.pageSizes"
                 :total="tablePage.totalResult" :layouts="layouts" @page-change="pageChange"></vxe-pager>
    </div>
  </div>
</template>
<script>
import {
  mapState,
  mapActions
} from "vuex";

export default {
  name: 'BaseInfo',
  data() {
    return {
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: "left",
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      staffNameList: [],
    }
  },
  computed: {
    ...mapState([
      "layouts"
    ]),
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions([
      "getWorkPersonListServe2"
    ]),
    // 分页功能
    pageChange(item) {
      if (item.type == "size") {
        this.tablePage.currentPage = 1;
      } else {
        this.tablePage.currentPage = item.currentPage;
      }
      this.getStaffNameList();
    },
    initView() {
      this.getStaffNameList()
    },
    // 查询员工名单
    getStaffNameList() {
      this.getWorkPersonListServe2({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          compId: this.$route.params.id
        }
      }).then(res => {
        if (res.code == 200) {
          this.staffNameList = res.data.records;
          this.tablePage.totalResult = +res.data.total;
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
