<template>
  <div class="wrapper">
    <!-- 原企业服务基本信息页面 现暂时无用 暂留 -->
    <el-form :model="form" ref="form" label-width="100px">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="企业名称" prop="compName">
            <el-input v-model="form.compName" :disabled="true" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="办公地址">
            <el-input v-model="form.compAddres" :disabled="true" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="联系人">
            <el-input v-model="form.contPerson" :disabled="true" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系方式">
            <el-input v-model="form.contPhone" :disabled="true" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="签约开始日期">
            <el-date-picker style="width:100%;" v-model="form.sigFromDate" type="date" value-format="yyyy-MM-dd" :disabled="true"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="签约结束日期">
            <el-date-picker style="width:100%;" v-model="form.sigToDate" type="date" value-format="yyyy-MM-dd" :disabled="true"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'BaseInfo',
  data() {
    return {
      titleName: '',
      form: {},
      tablePage: {
        totalResult: 0,
        currentPage: 0,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 200, 500, 1000],
        perfect: true,
      },
      rules: {
        compName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
      },
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['addServeOrgsServe', 'updateOrgsServe']),
    initView() {
      this.titleName = '修改服务企业'
      this.itemData = JSON.parse(this.$route.query.item)
      //这里调用请求编辑时的详情接口渲染form表单数据
      this.form = {
        compName: this.itemData.compName,
        compAddres: this.itemData.compAddres,
        contPerson: this.itemData.contPerson,
        contPhone: this.itemData.contPhone,
        sigFromDate: this.itemData.sigFromDate,
        sigToDate: this.itemData.sigToDate,
        id: this.itemData.id,
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }
  .my-dropdown1 {
    height: 200px;
    overflow: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;
    li {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
  }
}
</style>
